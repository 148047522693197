import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getPhysicianAnalytics } from '../services/analytics-service';
import {
  GraphPoint,
  PhysicianMetricResponse,
  AnalyticsNumberOfLevelsEnum,
  AnalyticsSurgeryAgeEnum,
  AnalyticsGraphEnum,
  AnalyticsStatisticTrendEnum,
  GraphStatistic,
  AnalyticsColumnEnum,
} from '../models/analytics';
import { i18nGlobalInstance } from '../assets/i18n/index';
import { useSessionStorage } from '@vueuse/core';

export const useAnalyticsStore = defineStore('analytics', () => {
  const rawPhysicianMetrics = ref<PhysicianMetricResponse>();
  const physicianMetricsByPatient = computed<Map<string, Array<GraphPoint>>>(
    () => {
      const mappedByPatient = new Map();

      if (rawPhysicianMetrics.value?.graphPoints) {
        for (let i = 0; i < rawPhysicianMetrics.value.graphPoints.length; i++) {
          const graphPointIterable: GraphPoint =
            rawPhysicianMetrics.value.graphPoints[i];

          if (graphPointIterable.patientId) {
            const patientId = graphPointIterable.patientId;
            const patientMetrics: Array<GraphPoint> =
              mappedByPatient.get(patientId) || [];

            // creates a graph point row for 'AnalyticsColumnEnum.POSTOP_LATEST' for isLatest in graphPointIterable
            if (graphPointIterable.isLatest) {
              const gpDuplicate = { ...graphPointIterable };
              gpDuplicate.graphColumnType = AnalyticsColumnEnum.POSTOP_LATEST;
              patientMetrics.push(gpDuplicate);
            }

            // Below handles removing duplicates from same column and using only the latest
            //

            // find index of point already stored in array of patient equal to the graphColumnType of iterable
            const existingPointIndex: number = patientMetrics.findIndex(
              (gpFound: GraphPoint) =>
                gpFound.graphColumnType === graphPointIterable.graphColumnType
            );

            // if exist...
            if (existingPointIndex > -1) {
              // ...compare the acquisitionDateTimeUtc and replace if newer
              if (
                new Date(
                  patientMetrics[existingPointIndex].acquisitionDateTimeUtc
                ).getTime() <
                new Date(graphPointIterable.acquisitionDateTimeUtc).getTime()
              ) {
                patientMetrics[existingPointIndex] = graphPointIterable;
              }
            } else {
              // if no existing point matches just push
              patientMetrics.push(graphPointIterable);
            }
            // set new array of map
            mappedByPatient.set(patientId, patientMetrics);
          }
        }
      }
      return mappedByPatient;
    }
  );
  const flattenedGraphPoints = computed(() => {
    const flatGraphPoints = [];

    // dependant on physicianMetricsByPatient computed function
    if (physicianMetricsByPatient.value) {
      for (const [key, value] of physicianMetricsByPatient.value) {
        const patientId: string = key;
        const graphPoints: Array<GraphPoint> = value;
        const flatGraphPointforPatient: any = {
          patientId: patientId,
          patientFirstName: graphPoints[0].patient.patientFirstName,
          patientLastName: graphPoints[0].patient.patientLastName,
        };

        // flattens GraphPoint
        for (let i = 0; i < graphPoints.length; i++) {
          const graphPoint: GraphPoint = graphPoints[i];
          if (graphPoint.pt !== null) {
            flatGraphPointforPatient['pt_' + graphPoint.graphColumnType] =
              Math.round(graphPoint.pt);
          }
          if (graphPoint.sva !== null) {
            flatGraphPointforPatient['sva_' + graphPoint.graphColumnType] =
              Math.round(graphPoint.sva);
          }
          if (graphPoint.l4S1 !== null) {
            flatGraphPointforPatient['l4s1_' + graphPoint.graphColumnType] =
              Math.round(graphPoint.l4S1);
          }
          if (graphPoint.pillLow !== null) {
            flatGraphPointforPatient['pillLow_' + graphPoint.graphColumnType] =
              Math.round(graphPoint.pillLow);
          }
          if (graphPoint.pillAverage !== null) {
            flatGraphPointforPatient[
              'pillAverage_' + graphPoint.graphColumnType
            ] = Math.round(graphPoint.pillAverage);
          }
          if (graphPoint.pillHigh !== null) {
            flatGraphPointforPatient['pillHigh_' + graphPoint.graphColumnType] =
              Math.round(graphPoint.pillHigh);
          }
        }
        flatGraphPoints.push(flatGraphPointforPatient);
      }
      return flatGraphPoints;
    } else {
      return [];
    }
  });

  const isAnalyticsLoading = ref(false);
  const failedToLoad = ref(false);

  const physicianStatistics = computed(() => {
    const statistics: {
      [key in AnalyticsGraphEnum]: GraphStatistic | undefined;
    } = {
      [AnalyticsGraphEnum.PT]: undefined,
      [AnalyticsGraphEnum.SVA]: undefined,
      [AnalyticsGraphEnum.L4S1]: undefined,
      [AnalyticsGraphEnum.PILL_LOW]: undefined,
      [AnalyticsGraphEnum.PILL_AVERAGE]: undefined,
      [AnalyticsGraphEnum.PILL_HIGH]: undefined,
    };

    Object.keys(statistics).forEach((key) => {
      const graphStatisticsForKey =
        rawPhysicianMetrics.value?.graphStatistics.filter(
          (gs: GraphStatistic) => gs.graphType === key && gs.graphType
        );

      // @ts-expect-error
      statistics[key] = {
        [AnalyticsStatisticTrendEnum.PLUS_MINUS_FIVE_DEGREES]:
          graphStatisticsForKey?.find(
            (gs: GraphStatistic) =>
              gs.trendType ===
              AnalyticsStatisticTrendEnum.PLUS_MINUS_FIVE_DEGREES
          ),
        [AnalyticsStatisticTrendEnum.PLUS_MINUS_TEN_DEGREES]:
          graphStatisticsForKey?.find(
            (gs: GraphStatistic) =>
              gs.trendType ===
              AnalyticsStatisticTrendEnum.PLUS_MINUS_TEN_DEGREES
          ),
        [AnalyticsStatisticTrendEnum.PLUS_MINUS_FIFTEEN_DEGREES]:
          graphStatisticsForKey?.find(
            (gs: GraphStatistic) =>
              gs.trendType ===
              AnalyticsStatisticTrendEnum.PLUS_MINUS_FIFTEEN_DEGREES
          ),
      };
    });

    return statistics;
  });

  const analyticsPageFilters = useSessionStorage('analytics-page-filters', {
    numberOfLevels: [
      {
        id: AnalyticsNumberOfLevelsEnum.LESS_THAN_FOUR,
        text: '< 4',
        selected: false,
      },
      {
        id: AnalyticsNumberOfLevelsEnum.FOUR_TO_SEVEN,
        text: '4-7',
        selected: false,
      },
      {
        id: AnalyticsNumberOfLevelsEnum.EIGHT_TO_THIRTEEN,
        text: '8-13',
        selected: false,
      },
      {
        id: AnalyticsNumberOfLevelsEnum.THIRTEEN_PLUS,
        text: '>13',
        selected: false,
      },
    ],
    surgeryAge: [
      {
        id: AnalyticsSurgeryAgeEnum.LESS_THAN_THIRTY_FIVE,
        text: '< 35',
        selected: false,
      },
      {
        id: AnalyticsSurgeryAgeEnum.THIRTY_FIVE_TO_FORTY_FOUR,
        text: '35-44',
        selected: false,
      },
      {
        id: AnalyticsSurgeryAgeEnum.FOURTY_FIVE_TO_FIFTY_FOUR,
        text: '45-54',
        selected: false,
      },
      {
        id: AnalyticsSurgeryAgeEnum.FIFTY_FIVE_TO_SIXTY_FOUR,
        text: '55-64',
        selected: false,
      },
      {
        id: AnalyticsSurgeryAgeEnum.SIXTY_FIVE_TO_SEVENTY_FOUR,
        text: '65-74',
        selected: false,
      },
      {
        id: AnalyticsSurgeryAgeEnum.SEVENTY_FOUR_PLUS,
        text: '> 74',
        selected: false,
      },
    ],
    chartFilter: [
      {
        filter: 'allKey',
        key: i18nGlobalInstance.t('analytics.common.all'),
        value: i18nGlobalInstance.t('analytics.common.all'),
        text: i18nGlobalInstance.t('analytics.common.all'),
        toggled: true,
      },
      {
        filter: 'pelvicKey',
        key: i18nGlobalInstance.t(`analytics.charts.${AnalyticsGraphEnum.PT}`),
        value: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.PT}`
        ),
        text: i18nGlobalInstance.t(`analytics.charts.${AnalyticsGraphEnum.PT}`),
        toggled: false,
      },
      {
        filter: 'svaKey',
        key: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.SVA}`,
          1
        ),
        value: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.SVA}`,
          0
        ),
        text: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.SVA}`,
          0
        ),
        toggled: false,
      },
      {
        filter: 'lordosisKey',
        key: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.L4S1}`,
          2
        ),
        value: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.L4S1}`
        ),
        text: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.L4S1}`
        ),
        toggled: false,
      },
      {
        filter: 'pillLowKey',
        key: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.PILL_LOW}`
        ),
        value: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.PILL_LOW}`
        ),
        text: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.PILL_LOW}`
        ),
        toggled: false,
      },
      {
        filter: 'pillAverageKey',
        key: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.PILL_AVERAGE}`
        ),
        value: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.PILL_AVERAGE}`
        ),
        text: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.PILL_AVERAGE}`
        ),
        toggled: false,
      },
      {
        filter: 'pillHighKey',
        key: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.PILL_HIGH}`
        ),
        value: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.PILL_HIGH}`
        ),
        text: i18nGlobalInstance.t(
          `analytics.charts.${AnalyticsGraphEnum.PILL_HIGH}`
        ),
        toggled: false,
      },
    ],
  });

  async function fetchPhysicianMetrics() {
    isAnalyticsLoading.value = true;
    const queryParams = {};
    const numberOfLevelsEnum = analyticsPageFilters.value.numberOfLevels.find(
      (n) => n.selected
    )?.id;
    const surgeryAgesEnum = analyticsPageFilters.value.surgeryAge.find(
      (s) => s.selected
    )?.id;

    if (numberOfLevelsEnum) {
      // @ts-ignore
      queryParams['numberOfLevelsEnum'] = numberOfLevelsEnum;
    }
    if (surgeryAgesEnum) {
      // @ts-ignore
      queryParams['surgeryAgeEnum'] = surgeryAgesEnum;
    }

    try {
      rawPhysicianMetrics.value = await getPhysicianAnalytics(queryParams);
    } catch (e) {
      failedToLoad.value = true;
    } finally {
      isAnalyticsLoading.value = false;
    }
  }

  function resetFiltersAndClearSessionStorage() {
    sessionStorage.removeItem('analytics-page-filters'); // CS-VP-SRS-936
    // chart fitlers
    analyticsPageFilters.value.chartFilter.forEach((filter) => {
      filter.toggled = false;
    });
    const allPill = analyticsPageFilters.value.chartFilter.find(
      (filter) => filter.filter === 'allKey'
    );
    if (allPill) {
      allPill.toggled = true;
    }

    analyticsPageFilters.value.surgeryAge.forEach((filter) => {
      filter.selected = false;
    });

    analyticsPageFilters.value.numberOfLevels.forEach((filter) => {
      filter.selected = false;
    });
  }

  return {
    fetchPhysicianMetrics,
    resetFiltersAndClearSessionStorage,
    physicianStatistics,
    flattenedGraphPoints,
    analyticsPageFilters,
    isAnalyticsLoading,
    failedToLoad,
    rawPhysicianMetrics,
    physicianMetricsByPatient, // todo delete
  };
});
