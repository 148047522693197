export const surgeryAges = ['< 35', '35-44', '45-54', '55-64', '65-74', '> 75'];

export const numberOfLevels = ['< 4', '4-7', '8-13', '13+'];

export interface AnalyticsChart {
  chartName: string;
  chartHeader: string;
  chartRawName: string;
  chartRawKey: string;
  chartColumns: Array<AnalyticsChartColumns>;
  chartData: any;
}

export interface AnalyticsChartColumns {
  key: string;
  label: Array<string> | string;
}

/// new models below
export interface PhysicianMetricResponse {
  id: string;
  physicianId: string;
  institutionId: string;
  createdOnDateTimeUtc: string;
  lastModifiedDateTimeUtc: string;
  graphStatistics: Array<GraphStatistic>;
  graphPoints: Array<GraphPoint>;
}

export interface GraphStatistic {
  id: string;
  graphType: AnalyticsGraphEnum;
  trendType: AnalyticsStatisticTrendEnum;
  physicianMetricEntityId: string;
  reportId: string;
  trendDifference: number;
  trendDifferenceSinceDateTimeUtc: string;
  trendPercentage: number;
}

export interface GraphPoint {
  id: string;
  patientId: string;
  physicianMetricEntityId: string;
  graphColumnType: AnalyticsColumnEnum;
  numberOfLevels: AnalyticsNumberOfLevelsEnum;
  surgeryAge: AnalyticsSurgeryAgeEnum;
  isLatest: boolean;
  pt: number | null;
  sva: number | null;
  l4S1: number | null;
  pillLow: number | null;
  pillAverage: number | null;
  pillHigh: number | null;
  acquisitionDateTimeUtc: string;
  patient: {
    patientId: string;
    patientFirstName: string;
    patientLastName: string;
  };
}

export interface GraphStatisticMapped {
  [AnalyticsStatisticTrendEnum.PLUS_MINUS_FIVE_DEGREES]: GraphStatistic;
  [AnalyticsStatisticTrendEnum.PLUS_MINUS_TEN_DEGREES]: GraphStatistic;
  [AnalyticsStatisticTrendEnum.PLUS_MINUS_FIFTEEN_DEGREES]: GraphStatistic;
}

export enum AnalyticsGraphEnum {
  PT = 'PT',
  SVA = 'SVA',
  L4S1 = 'L4S1',
  PILL_LOW = 'PILL_LOW',
  PILL_AVERAGE = 'PILL_AVERAGE',
  PILL_HIGH = 'PILL_HIGH',
}

export enum AnalyticsNumberOfLevelsEnum {
  LESS_THAN_FOUR = 'LESS_THAN_FOUR',
  FOUR_TO_SEVEN = 'FOUR_TO_SEVEN',
  EIGHT_TO_THIRTEEN = 'EIGHT_TO_THIRTEEN',
  THIRTEEN_PLUS = 'THIRTEEN_PLUS',
}

export enum AnalyticsSurgeryAgeEnum {
  LESS_THAN_THIRTY_FIVE = 'LESS_THAN_THIRTY_FIVE',
  THIRTY_FIVE_TO_FORTY_FOUR = 'THIRTY_FIVE_TO_FORTY_FOUR',
  FOURTY_FIVE_TO_FIFTY_FOUR = 'FOURTY_FIVE_TO_FIFTY_FOUR',
  FIFTY_FIVE_TO_SIXTY_FOUR = 'FIFTY_FIVE_TO_SIXTY_FOUR',
  SIXTY_FIVE_TO_SEVENTY_FOUR = 'SIXTY_FIVE_TO_SEVENTY_FOUR',
  SEVENTY_FOUR_PLUS = 'SEVENTY_FOUR_PLUS',
}

export enum AnalyticsColumnEnum {
  PREOP = 'PREOP',
  POSTOP_LATEST = 'POSTOP_LATEST',
  POSTOP_LESS_THAN_ONE_YR = 'POSTOP_LESS_THAN_ONE_YR',
  POSTOP_MORE_THAN_ONE_YR = 'POSTOP_MORE_THAN_ONE_YR',
}

export enum AnalyticsStatisticTrendEnum {
  PLUS_MINUS_FIVE_DEGREES = 'PLUS_MINUS_FIVE_DEGREES',
  PLUS_MINUS_TEN_DEGREES = 'PLUS_MINUS_TEN_DEGREES',
  PLUS_MINUS_FIFTEEN_DEGREES = 'PLUS_MINUS_FIFTEEN_DEGREES',
}
