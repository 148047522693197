<script lang="ts" setup>
import { computed, defineProps, useAttrs } from 'vue';

const props = defineProps({
  icon: {
    type: String || null,
    required: false,
    default: null,
  },
  iconSize: {
    type: Number,
    required: false,
    default: 18,
  },
  iconColorChange: {
    type: Boolean,
    required: false,
    default: true,
  },
  iconPosition: {
    type: String,
    required: false,
    default: 'left',
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const attrs = useAttrs();
const pixelSize = computed(() => {
  return props.iconSize + 'px';
});
const showCheckmarkIcon = computed(() => {
  if (attrs.class) {
    return (attrs.class as string).includes('success');
  }

  return false;
});
const showProccessingIcon = computed(() => {
  if (attrs.class) {
    return (attrs?.class as string).includes('processing');
  }

  return false;
});
</script>
<template>
  <button class="btn" :disabled="disabled">
    <img
      v-if="icon"
      :src="icon"
      alt="icon"
      :class="iconColorChange ? 'custom-icon' : ''"
    />
    <img
      v-if="showCheckmarkIcon && !icon"
      src="../../../assets/img/checkmark.svg"
      alt="checkmark"
    />
    <img
      v-if="showProccessingIcon && !icon"
      src="../../../assets/img/button-processing.svg"
      alt="processing"
    />
    <slot></slot>
  </button>
</template>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';

.btn {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  border: none;
  transition: all 100ms;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
}

.btn-no-outline {
  outline: 1px solid transparent !important;
  color: $brightgreen;
  background-color: transparent;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.btn-outline {
  outline: 1px solid rgba(219, 219, 219, 0.2);
  color: $brightgreen;
  background-color: transparent;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.btn-discrete {
  outline: 1px solid rgba(219, 219, 219, 0.2);
  color: $lightgrey;
  background-color: transparent;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.15);
  }
  &:disabled {
    outline: 1px solid rgba(219, 219, 219, 0.2);
    background-color: transparent !important;
    .custom-icon {
      filter: none;
    }
  }
}

.btn-primary {
  background-color: #02e600;
  color: #1b1b1b;
  font-weight: 500;
  &:hover {
    background-color: #36f133;
  }
}

.btn-pediatric {
  color: #272727 !important;
  background-color: $pediatricbackground !important;
}

.btn-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  max-height: 35px;
  max-width: 35px;
  min-height: 35px;
  min-width: 35px;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.1);
  &:hover {
    background-color: rgba(255, 255, 255, 0.14);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  max-height: 35px;
  max-width: 35px;
  min-height: 35px;
  min-width: 35px;
  background-color: rgba(0, 0, 0, 0);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.custom-icon {
  max-height: v-bind(pixelSize) !important;
  max-width: v-bind(pixelSize) !important;
  filter: invert(51%) sepia(97%) saturate(2135%) hue-rotate(81deg)
    brightness(111%) contrast(119%);
}

.btn-appended-icon {
  padding-right: 8px !important;
}

.pediatric {
  background-color: $pediatricbackground !important;
}

.btn:disabled,
btn[disabled] {
  background-color: rgba(255, 255, 255, 0.15);
  color: #afafaf;
  opacity: 50%;
  cursor: default;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.success,
.processing {
  background-color: #183418 !important;
  color: #d7e8cd !important;
  outline: none !important;
  padding-left: 15px !important;
  padding-right: 20px !important;
  cursor: default !important;
}
</style>
