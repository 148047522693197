// enums

import { ExamResponse } from './patient-exams';
import { IntakeForm } from './patient-list';

export enum AnalyticsTimingEnum {
  TaskTimingModeling = 'TaskTimingModeling',
  TaskTimingPlanning = 'TaskTimingPlanning',
  TaskTimingTotal = 'TaskTimingTotal',
  CaseTiming = 'CaseTiming',
}

export enum CasePriorityEnum {
  High = 0,
  Regular = 1,
}

export enum CasePriorityStringEnum {
  High = 'High',
  Regular = 'Regular',
}

export enum CaseTypeEnum {
  PreOp = 'PreOp',
  PostOp = 'PostOp',
  All = 'All',
}
export enum CaseFiltersEnum {
  All = 'All',
  PreopRod = 'PreopRod',
  Preop = 'PreOp',
  Postop = 'PostOp',
  Urgent = 'High',
  NotAssigned = 'Not Assigned',
  Successful = 'Successful',
  Rejected = 'Rejected',
}

export enum CompletionTypeEnum {
  Successful = 'Successful',
  Rejected = 'Rejected',
  All = 'All',
}

export enum ContraindicationEnum {
  PatientLessThan7YearsOld = 'PatientLessThan7YearsOld',
  InsufficientVisibility = 'InsufficientVisibility',
  CongenitalDeformity = 'CongenitalDeformity',
  PresenceOfPatientMotion = 'PresenceOfPatientMotion',
  MoreOrLessThan24Vertebrae = 'MoreOrLessThan24Vertebrae',
  Other = 'Other',
}

export enum SortOrderEnum {
  Ascending = 0,
  Descending = 1,
}

export enum StepTypeEnum {
  Initial_Modeling = 'InitialModeling',
  Modeling_QC = 'ModelingQC',
  Initial_Planning = 'InitialPlanning',
  Planning_QC = 'PlanningQC',
  Planning_Return_for_QC = 'PlanningReturnForQC',
  Completed = 'Completed',
  Rejected = 'Rejected',
}

export enum NextStepTypeEnum {
  Initial_Modeling = 'InitialModeling',
  Modeling_QC = 'Modeling_QC',
  Initial_Planning = 'InitialPlanning',
  Planning_QC = 'Planning_QC',
  Planning_Return_for_QC = 'Planning_ReturnforQC',
}

export enum TaskTypeEnum {
  Modeling = 'Modeling',
  Planning = 'Planning',
}

export enum SubRoleEnum {
  Modeling = 'Modeling',
  Planning = 'Planning',
  Manager = 'Manager',
}

// responses

export interface CaseDto {
  caseId: string;
  surgicalStrategyId: string;
  patientId: string;
  institutionId: string;
  priority: CasePriorityEnum | CasePriorityStringEnum;
  userId?: string;
  createdOn: string;
  contraindications: Array<ContraindicationEnum>;
  isPediatric: boolean;
  initialModelingId: string;
  latestModelingId: string;
  isPostOp: boolean;
  inProgress: boolean;
  tasks: Array<TaskDto>;
  patientLastName: string;
  patientFirstName: string;
  patientDateOfBirth: string;
  patientSex: string;
  physicianLastName: string;
  physicianFirstName: string;
  physicianSuffix: string;
  isRodOrdered: boolean;
  institutionName: string;
  returnedOn: string;
}

export interface TaskDto {
  createdOn: string;
  taskDescription: string;
  taskType: TaskTypeEnum;
  steps: Array<StepDto>;
}

export interface StepDto {
  createdOn: string;
  startedOn?: string;
  completedOn?: string;
  userId?: string;
  stepDescription: string;
  stepType: StepTypeEnum;
  latestModelingId?: string;
  latestModelingModifiedOn?: string;
  isRejected: boolean;
  note: string;
  userFirstName?: string;
  userLastName?: string;
}

export interface CompletedTaskDurationStatsDto {
  caseCount?: number;
  taskCount?: number;
  averageTaskTimeSeconds: number;
  minTaskTimeSeconds: number;
  maxTaskTimeSeconds: number;
}

export interface InstitutionCompletedTasksCountDto {
  institutionId: string;
  completedTasks: number;
}

export interface UserCompletedTasksCountDto {
  userId: string;
  completedTasks: number;
}

// requests

export interface AddNoteCommandDto {
  message: string;
}

export interface AssignUserUnassignedTasksCommandDto {
  userId: string;
  subRole: SubRoleEnum;
}

export interface CompletedTaskStatsByInstitutionQueryDto {
  startDate: string;
  endDate: string;
  institutionIds: Array<string>;
}

export interface CompletedTaskStatsByUserQueryDto {
  startDate: string;
  endDate: string;
  userIds: Array<string>;
}

export interface CreateCaseCommandDto {
  eosAcquisitionId: string;
  patientId: string;
  institutionId: string;
  priority: CasePriorityEnum;
  initialModelingId: string;
  isPostOp: boolean;
}

export interface DurationsStatsQueryDto {
  startDate?: string;
  endDate?: string;
  institutionIds?: Array<string>;
  completionType: CompletionTypeEnum;
  timingType: AnalyticsTimingEnum;
  caseType: CaseTypeEnum;
}

export interface GetCasesQueryDto {
  filterByUserId: boolean;
}

export interface RejectTaskCommandDto {
  Contraindications: Array<ContraindicationEnum>;
  comment?: string;
}

export interface RejectCaseCommandDto extends RejectTaskCommandDto {
  note: string;
}

export interface TaskAutoAssignmentCommandDto {
  onlineUserIds: Array<string>;
}

// service respones

export interface GetAllCasesResponse {
  totalCount: number;
  items: Array<CaseDto>;
}
export interface GetAllCasesQueryDto {
  assigned?: boolean;
  hasRod?: boolean;
  caseType?: string;
  priority?: string;
  completed?: boolean;
  completionType?: string;
  filterByUserId?: boolean;
  page: number;
  limit: number;
  totalCount: number;
  items: CaseDto;
}

// component models

export interface CaseAndPatient {
  case: CaseDto;
  patientData: ExamResponse;
}

export interface AnalyticsCaseData {
  totalCases?: number;
  preOpCases?: number;
  postOpCases?: number;
  successfulCases?: number;
  rejectedCases?: number;
  averageCaseTime?: number;
  minCaseTime?: number;
  maxCaseTime?: number;
}

export interface AnalyticsTaskData {
  totalTasks?: number;
  modelingTasks?: number;
  planningTasks?: number;
  averageTotalTaskTime?: number;
  minTotalTaskTime?: number;
  maxTotalTaskTime?: number;
  averageModelingTaskTime?: number;
  minModelingTaskTime?: number;
  maxModelingTaskTime?: number;
  averagePlanningTaskTime?: number;
  minPlanningTaskTime?: number;
  maxPlanningTaskTime?: number;
}

export interface Case3dWithIntakeForm {
  case: CaseDto;
  intakeForm: IntakeForm;
}
